import { User as UserIcon } from 'iconsax-react'
import { useCallback } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { isAxiosError } from 'axios'
import { Form, FormInput, FormSubmit } from '../../components'
import { ROUTES_PATH } from '../../routes'
import {
  MessageTypeProps,
  SignInErrorResponse,
  SignInFormProps,
  signInFormSchema
} from './types'
import 'react-toastify/dist/ReactToastify.css'
import { api } from '../../services/api'
import { useAuth } from '../../store'
import { SignInDiv } from '../../components/SignInDiv'
import { SocialLoginButton } from '../../components/SocialLoginButton'

export function SignIn() {
  const { t, i18n } = useTranslation()
  const { setToken, setEmail } = useAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const signInForm = useForm<SignInFormProps>({
    resolver: zodResolver(signInFormSchema),
    defaultValues: {
      email: searchParams?.get('email') || '',
      password: ''
    }
  })
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting }
  } = signInForm

  const onSubmit = useCallback(
    async (data: SignInFormProps) => {
      const gTagSignIn = JSON.stringify({'send_to': 'AW-16708129960/isD4CJTmnNQZEKiph58-'});
      <script>  gtag('event', 'conversion', {gTagSignIn});</script>
      try {
        const { email, password } = data
        const payload = {
          email,
          password
        }
        const response = await api.post<{ access_token: string, redirect_url?: string }>(
          'accounts/sign-in',
          payload,
          {
            withCredentials: true
          }
        )
        const { access_token, redirect_url } = response.data

        if (access_token) {
          setToken(access_token)
          setEmail(email)
          navigate(-1)
        }
        else redirect_url && navigate(redirect_url)
      } catch (error) {
        if (isAxiosError<SignInErrorResponse>(error)) {
          const errorMessage = error?.response?.data?.messages

          if (errorMessage) {
            errorMessage.forEach(message => {
              toast.error(
                message?.Message[i18n.language as keyof MessageTypeProps] ||
                t('sign-in.request-error'),
                {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  theme: 'dark'
                }
              )
              switch (message?.Item) {
                case 'user_email_not_confirmed': {
                  console.log('message', message)
                  navigate(
                    `${ROUTES_PATH.HOME.path
                    }?notification=${encodeURIComponent(
                      JSON.stringify(message)
                    )}`
                  )
                  break
                }
                case 'user_phone_not_confirmed': {
                  navigate(
                    `${ROUTES_PATH.VERIFY_PHONE.path}?token=${message.Complement}`
                  )
                  break
                }
              }
            })
          }
        }
        // console.log({ error })
      }
    },
    [i18n.language, navigate, setEmail, setToken, t]
  )
  return (
    <>
      <script> fbq('track', 'Lead'); </script>
      <SignInDiv id={ROUTES_PATH.SIGN_IN.path}>
        <h1 className="text-3xl">{t('sign-in.title')}</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...signInForm}>
            <FormInput
              inputName="email"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('sign-in.form.email')}
              errorMessage={errors.email?.message}
              {...register('email')}
            />
            <FormInput
              inputName="password"
              className="h-12 text-base placeholder:text-base"
              placeholder={t('sign-in.form.password')}
              type="password"
              errorMessage={errors.password?.message}
              {...register('password')}
            />
            <div className="flex justify-between text-sm text-amz3green-50">
              <Link
                to={ROUTES_PATH.RESET_PASSWORD.path}
                className="underline ml-auto"
              >
                {t('sign-in.forget-password')}
              </Link>
            </div>
            <FormSubmit isSubmitting={isSubmitting} className="h-12 text-base">
              <span className="text-base font-medium">
                {t('sign-in.form.submit-btn')}
              </span>
              <UserIcon />
            </FormSubmit>
          </FormProvider>
        </Form>
        <Link
          to={ROUTES_PATH.SIGN_UP.path}
          className="border border-amz3green-150 text-amz3green-150 bg-amz3black-500 mt-4 rounded-2lg w-full h-12 flex justify-center items-center gap-4 font-medium text-base hover:bg-amz3black-200 transition-all duration-200 ease-in leave:opacity-0 leave:duration-200 leave:ease-in"
        >
          {t('sign-in.sign-up')}
        </Link>
        <SocialLoginButton />
      </SignInDiv>
      {/* <ToastContainer /> */}
    </>
  )
}


